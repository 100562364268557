import React, { useEffect, Fragment } from "react";
import { scrollToElement } from "../../../utility/utils";

const ResponseA = () => {
    useEffect(() => {
        scrollToElement()
        window.addEventListener("scroll", function () {
            scrollToElement()
        })
    }, [])

    return (
        <Fragment>
            <div className="kuda-inner--heading kuda-disclaimer--section mt-0">
                <div className="kuda-section--inner">
                    <div className="kuda-section--100">
                        <p className="color-black text-center title-bottom--spacing">
                            Thank you, your response has been submitted.
                        </p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ResponseA