import React from "react"
import ResponseA from "../../components/body/pages/newsletter-email/responseA"
import NoheaderFooter from "../../components/Noheaderfooter"
import SEO from "../../components/seo"

const ResponseARoute = () => (
  <NoheaderFooter>
    <SEO title="Kuda Monthly Newsletter" />
    <ResponseA />
  </NoheaderFooter>
)
export default ResponseARoute
